import { graphql } from "gatsby";
import React from "react";
import GraphQLErrorList from "../components/BlogPost/graphql-error-list";
import BookingSection from "../components/BookingSection";
import ClientSection from "../components/ClientSection";
import Layout from "../components/Layout";
import PlansSection from "../components/PlansSection";
import RecoverySection from "../components/RecoverySection";
import Seo from "../components/SEO";
import ServicecContentSection from "../components/ServicecContentSection";
import ServicesHero from "../components/ServicesHero";
import Testimonial from "../components/Testimonial";
import PopUp from "../components/PopUp";

const Services = (props) => {
  const { data, errors } = props;
  const service = data && data.service;
  const site = data && data.site;
  const heroTitle = service?.title;
  const faqs = service?.faqs;

  return (
    <>
      <Layout>
        {errors && <Seo title="GraphQL Error" />}
        {service && (
          <Seo title={service.seoTitle} description={service.metaDescription} />
        )}

        {errors && (
          <div className="container">
            <GraphQLErrorList errors={errors} />
          </div>
        )}
        <PopUp type="service"/>
        <ServicesHero {...service} title={heroTitle} />
        <ClientSection />
        <ServicecContentSection {...site} {...service} {...faqs} />
        <PlansSection />
        <Testimonial />
        <RecoverySection heading="More Ways to Recover, Relax & Recharge" />
        <BookingSection />
      </Layout>
    </>
  );
};

export default Services;

export const query = graphql`
  query ServiceQuery($id: String!) {
    service: sanityServices(id: { eq: $id }) {
      id
      featuredImage {
        asset {
          gatsbyImageData
          url
        }
      }
      featuredImageMobile {
        asset {
          gatsbyImageData
          url
        }
      }
      title
      slug {
        current
      }
      faqs {
        faqsTitle
        questions {
          questionText
          _rawAnswer
        }
      }
      seoTitle
      metaDescription
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
