import React from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import Card from "../Card";
import { StaticImage } from "gatsby-plugin-image";

const RecoverySection = ({ heading = "Heighten Your Recovery" }) => {
  const split = heading.split(" ");
  const lastWord = split[split.length - 1];




  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute sm:block hidden left-[-8%] top-[50%] translate-x-[-0%] opacity-[.9] hover:opacity-[1] translate-y-[-50%] z-10"
      >
        <StaticImage
        placeholder="none"
          src="../../static/svgs/testimonail-arrow-right.svg"
          alt="right-arrow"
          className="cursor-pointer"
        />
      </button>
    );
  };
  
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute sm:block hidden right-[3%] top-[50%] translate-x-[-0%] translate-y-[-50%] opacity-[.9] hover:opacity-[1] z-10"
      >
        <StaticImage
        placeholder="none"
          src="../../static/svgs/testimonail-arrow-left.svg"
          alt="left-arrow"
          className="cursor-pointer"
        />
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          dots: true,
          centerPadding: "40px",

          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 724,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

  const { allSanityServices } = useStaticQuery(graphql`
    query GetServicesCardData {
      allSanityServices {
        nodes {
          title
          slug {
            current
          }
          cardDescription
          featuredImageMobile {
            asset {
              url
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const services = allSanityServices.nodes;

  return (
    <section className="w-full mb-[100px] lg:mb-[131px]">
      <div className="w-4/5 mx-auto">
        <h2 className="fontFamily2 font-bold text-[42px] leading-[51px] text-black_typography mb-12">
          {heading.replace(lastWord, "")}{" "}
          <span className="gradieant_text">{lastWord}</span>
        </h2>
      </div>
      <div className="w-[90%] ml-auto recovery_container">
        <Slider {...settings}>
          {services.map((data) => {
            return (
              <div key={data} className="!w-[95%] pb-10 lg:pb-0">
                <Card data={data} type={"secondary"} />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default RecoverySection;
