import React from "react";
import Accordian from "../Accordian";

const CryotherapyFaqs = ({ questions, dataType }) => {
  return (
    <>
      {questions?.map((data) => {
        return (
          <Accordian key={data?.questionText} data={data} type={dataType} />
        );
      })}
    </>
  );
};

export default CryotherapyFaqs;
