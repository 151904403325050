import React from "react";
import { StyledContent } from "../BlogPost";
import PortableText from "../BlogPost/portableText";
import Button from "../Button";
import AccordianFAQs from "../CryotherapyFaq";
import styled from "styled-components";
import tw from "twin.macro";

export const Content = styled(StyledContent)`
  h1,
  h2 {
    ${tw` text-4xl font-bold  text-gray_900 mb-6 mt-16	first:mt-0`}
  }
  h3 {
    ${tw` text-3xl font-semibold  text-gray_900 mb-6 mt-16	`}
  }
  p {
    ${tw`text-base text-typography_body leading-7 `}
    font-family: "Bitter", serif;
  }
  ul {
    ${tw`ml-[-28px] list-inside`}
  }
  li {
    font-family: "Bitter", serif;
    ${tw`text-base text-typography_body leading-7 mb-0 `}
  }
`;

const ServicecContentSection = ({
  faqsTitle,
  questions,
  _rawBody,
  toggleFunc,
}) => {
  const cardData = [
    {
      heading: "Question goes here lorem ipsum dolor sit amet",
      infoText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
    },
    {
      heading: "Question goes here lorem ipsum dolor sit amet",
      infoText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
    },
    {
      heading: "Question goes here lorem ipsum dolor sit amet",
      infoText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
    },
    {
      heading: "Question goes here lorem ipsum dolor sit amet",
      infoText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
    },
  ];

  return (
    <section className="w-4/5 flex flex-col gap-16 lg:flex-row lg:gap-[120px] mx-auto mb-[120px] lg:mb-0 mt-[121px]">
      <div className="lg:w-[65%] 2xl:w-[70%]">
        <Content>{_rawBody && <PortableText blocks={_rawBody} />}</Content>
        <div className="w-full  mb-0 lg:mb-[120px] mt-[64px]">
          <h2 className="text-4xl font-bold  text-gray_900 mb-6 mt-16	first:mt-0">
            {faqsTitle} Q&A
          </h2>
          <div>
            <AccordianFAQs
              questions={questions || cardData}
              dataType={"secondary"}
            />
          </div>
        </div>
      </div>
      <aside className="lg:w-[35%] 2xl:w-[30%]  flex justify-center">
        <div className="w-full h-auto pb-6 sm:pb-0  sm:w-[340px] lg:w-full border_color rounded-sm pl-[37px] sm:h-[381px]  ">
          <h3 className="font-semibold text-2xl leading-[34px] fontFamily2 mb-4 mt-[18px] text-black_typography">
            Interested in {faqsTitle}?
          </h3>
          <p className="font-normal fontFamily1 text-base leading-7 text-typography_body pr-5">
            We’re happy to consult with you and answer any questions you might
            have. Or you can book a time to try it out!
          </p>
          <div className="flex flex-col gap-4 mt-4">     
          <Button
            onClick={() => toggleFunc("contact")}    
              texts={"Contact"}
              customClass={
                "uppercase text-base font-semibold text-primary_green fontFamily2 w-[90%]   2xl:w-[243px]  tracking-[0.03em] rounded-sm border-primary_green border-[1px] h-12 transition hover:bg-[#f1f1f1]"
              }
            />
            <a href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/all-offer?pId=400656">
              <Button
                texts={"Book Now"}
                customClass={
                  "uppercase text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em] w-[165px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
                }
              />
            </a>
          </div>
        </div>
      </aside>
    </section>
  );
};

export default ServicecContentSection;
