import React from "react";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import { get } from "lodash";
import { useMediaPredicate } from "react-media-hook";

const ServicesHero = ({ title, featuredImage,featuredImageMobile }) => {
  const isDesktop = useMediaPredicate("(min-width: 768px)");
  const image = getImage(get(isDesktop ? featuredImage : featuredImageMobile, "asset.gatsbyImageData"));
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
    >
      <div className="mt-12 lg:mt-20 h-[335px]  mb-[45px] lg:mb-[123px] lg:h-[504px] w-full flex justify-center items-center bg-no-repeat bg-cover bg-center">
        <p className="font-extrabold text-[40px] lg:text-[52px] flex justify-center items-center  text-white w-[240px]  lg:w-[350px] leading-[49px] text-center lg:leading-[63px] ">
          {title}
        </p>
      </div>
    </BackgroundImage>
  );
};

export default ServicesHero;
