import classNames from "classnames";
import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { StyledContent } from "../BlogPost";
import PortableText from "../BlogPost/portableText";
import styled from "styled-components";
import tw from "twin.macro";

export const Content = styled(StyledContent)`
  h1,
  h2 {
    ${tw` text-lg font-bold  text-gray_900 mb-6`}
  }
  h3 {
    ${tw` text-3xl font-semibold  text-gray_900 mb-6 `}
  }
  p {
    ${tw`text-base text-typography_body leading-7 mt-6`}
  }
`;

const Accordian = ({ data, type }) => {
  const question = data?.questionText;
  const _rawAnswer = data?._rawAnswer;

  const [active, setActive] = useState(false);

  const toggleActive = (active) => {
    setActive(!active);
  };

  const isSecondary = type === "secondary";
  return (
    <div key={question}
      className={classNames(
        "w-full  last:border-b-[1px] border-gray_200 border-t-[1px]",
        isSecondary ? "py-6" : "py-8 ",
        active ? "pb-0" : ""
      )}
    >
      <div>
        <button
          onClick={() => toggleActive(active)}
          className={classNames(
            "w-full flex justify-between text-left items-center font-semibold fontFamily2 cursor-pointer  text-gray_900",
            isSecondary ? "text-lg leading-6" : "text-xl leading-[27px]"
          )}
        >
          {question}
          <StaticImage
          placeholder="none"
            src="../../static/svgs/accordain-cancel.svg"
            alt="phone-icon"
            className={classNames(
              "w-6 cursor-pointer h-6",
              active ? "!block" : "!hidden"
            )}
          />
          <StaticImage
          placeholder="none"
            src="../../static/svgs/accordain-plus.svg"
            alt="phone-icon"
            className={classNames(
              "w-6 cursor-pointer h-6 ",
              active ? "!hidden" : "!block"
            )}
          />
        </button>
      </div>
      <div
        className={classNames("overflow-hidden ", active ? "h-auto" : "h-0")}
      >
        <Content>{_rawAnswer && <PortableText blocks={_rawAnswer} />}</Content>
      </div>
    </div>
  );
};

export default Accordian;
